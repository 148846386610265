import { Input, Modal } from 'antd';
import { useState } from 'react';
import { sendRequest } from '../../lib/api';
import './styles.css';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const InviteUserModal = ({ visible, onClose }: Props) => {
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [disabled, setDisabled] = useState(false);

  const confirmClick = async () => {
    if (disabled) return;
    if (!email) {
      window.alert('Please enter an email');
      return;
    }
    setDisabled(true);
    const res = await sendRequest(
      { email, comment: comment.length > 0 ? comment : null },
      'POST',
      'api/invite-user',
    );
    if (res.status) {
      window.alert('Invitation sent successfully');
      onClose();
    } else {
      setDisabled(false);
      window.alert(res.data?.message ?? 'Error');
    }
  };

  return (
    <Modal
      title='Invite Another Sooner Grad:'
      onOk={confirmClick}
      onCancel={onClose}
      open={visible}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText='Invite This OU Alumni'
    >
      <div className='wrap'>
        <h3 className='mt-10 text-center ml-10 mr-10'>
          {
            'Please ONLY invite graduates from the University of Oklahoma. Thank you!'
          }
        </h3>
        <div className='mt-20 mb-20'>
          <label>Email Address:</label>
          <Input
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className='mb-20'>
          <label>Add A Personal Message (optional)</label>
          <Input.TextArea
            value={comment}
            onChange={e => setComment(e.target.value)}
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InviteUserModal;
